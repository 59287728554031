import React from 'react';
import { useNavigate } from 'react-router-dom';

const ScrollToSectionLink = ({ to, children }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
    setTimeout(() => {
      document.getElementById(to)?.scrollIntoView({ behavior: 'smooth' });
    }, 100); // Delay to allow for navigation
  };

  return (
    <div onClick={handleClick} style={{ cursor: 'pointer' }}>
      {children}
    </div>
  );
};

export default ScrollToSectionLink;
