import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Portfolio from './components/Portfolio';
import About from './components/About';
import Contact from './components/Contact';
import Services from './components/Services';
import Footer from './components/Footer';
import './css/App.css';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import PortfolioItem from './components/PortfolioItem'; // New component

import Testimonials from './components/Testimonials';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <Hero />
              <About />
              <Portfolio />
              <Services />
              <Testimonials />
              <GoogleReCaptchaProvider reCaptchaKey="6LdRrQoqAAAAAE4Exyqnj_2jOQJzLjH0pnapnSpo">
                <Contact />
              </GoogleReCaptchaProvider>
            </>
          } />
          <Route path="/:title" element={<PortfolioItem />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
