// Footer.js
import React from 'react';
import '../css/footer.css'; // Ensure correct path to footer.css

function Footer() {

  const currentYear = new Date().getFullYear();


  return (
    <footer id="Footer">
      <div className='parent'>
        <div className='div1'>
          <div className='footer-links'>
            <a href="#about">About</a>
            <a href="#portfolio">Portfolio</a>
            <a href='https://calendly.com/photosbyamna/60min' target='_blank' rel="noreferrer">Book Minis</a>
            <a href="#services">Services</a>
          </div>
          <div className='footer-bottom'>
            <p>Ready to get started?</p>
            <h1 className='no-margins'>I'd love to know the details!</h1>
            <button className='message-button' href="#contact">Send a Message
            <img src='./assets/pencil.svg'></img>
            </button>
          </div>
        </div>
        <div className='div2'>
          <a href="https://www.instagram.com/galwhosnaps/?hl=en" target='_blank'>Let's connect</a>
          <div className='copyright'>Copyright @ Photos by Amna <span id='copy-year'>{currentYear}</span></div>
          </div>
        
      </div>
    </footer>
  );
}

export default Footer;
