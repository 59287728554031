import React, { useEffect, useState } from 'react';
import '../css/testimonials.css';

const Testimonials = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const images = [
    { left: '/assets/testimonials/zakia-review.jpg', right: '/assets/testimonials/zakia.png' },
    { left: '/assets/testimonials/yazel-review.jpg', right: '/assets/testimonials/yazel.png' },
    { left: '/assets/testimonials/tanaz-review.jpg', right: '/assets/testimonials/tanaz.png' },
    { left: '/assets/testimonials/shawana-review.jpg', right: '/assets/testimonials/shawana.png' },
    { left: '/assets/testimonials/amna-review.jpg', right: '/assets/testimonials/amna.png' },
    { left: '/assets/testimonials/ahmet-review.jpg', right: '/assets/testimonials/ahmet.png' },
    { left: '/assets/testimonials/alysia-review-2.jpg', right: '/assets/testimonials/alysia.png' },
    { left: '/assets/testimonials/fion-review.jpg', right: '/assets/testimonials/fion.png' },
  ];

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + images.length) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 15000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const testimonialsSection = document.getElementById('testimonials');
      const scrollingTextRight = document.querySelector('.scrolling-text-right h6');
      const scrollingTextLeft = document.querySelector('.scrolling-text-left h6');

      if (testimonialsSection) {
        const sectionTop = testimonialsSection.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        const scrollValue = window.scrollY - testimonialsSection.offsetTop;

        if (sectionTop < windowHeight && sectionTop > -testimonialsSection.offsetHeight) {
          if (scrollingTextRight) {
            scrollingTextRight.style.transform = `translateX(${scrollValue}px)`;
          }
          if (scrollingTextLeft) {
            scrollingTextLeft.style.transform = `translateX(${-scrollValue}px)`;
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section id="testimonials">
      <div className="scrolling-text-right">
        <h6>
          Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  
          Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  
          Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  
          Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  
          Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  
          Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  
          Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  
          Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  
          Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  
          Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp; 
        </h6>
      </div>

      <div className="carousel">
        
        <div className="carousel-wrapper" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
          {images.map((image, index) => (
            <div
              key={index}
              className="carousel-slide">
              <div
                className="carousel-image left-image"
                style={{ backgroundImage: `url(${image.left})` }}>
              </div>
              <img src={image.right} alt="Right" className="carousel-image right-image" />
            </div>
          ))}
        </div>
      <div className='next-btn'>
        <div className="arrow right" onClick={nextSlide}><img src="/assets/arrow-black.svg" alt="Next" /></div>
      </div>
      </div>

      <div className="scrolling-text-left">
        <h6>
          Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  
          Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  
          Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  
          Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  
          Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  
          Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  
          Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  
          Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  
          Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  
          Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp;  Kind Words  &nbsp;  &nbsp;  Testimonials  &nbsp;  &nbsp; 
          
        </h6>
      </div>
    </section>
  );
};

export default Testimonials;



