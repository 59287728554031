import React, { useEffect, useState } from 'react';
import '../css/hero.css';

const images = [
  '/assets/hero-landscapes/timeless.jpg',
  '/assets/hero-landscapes/candid.jpg',
  '/assets/hero-landscapes/aesthetic.jpg',
  '/assets/hero-landscapes/creative.jpg',
  '/assets/hero-landscapes/photojournalistic.jpg',
  '/assets/hero-landscapes/true-to-life.jpg',
  '/assets/hero-landscapes/comfortable.jpg',
  '/assets/hero-landscapes/fun.jpg',
  '/assets/hero-landscapes/editorial.jpg',
  '/assets/hero-landscapes/special.jpg',
];

const Hero = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <section className="hero">
      <div className='news-banner'>Now accepting wedding bookings for 2025!</div>
      <div className="slideshow-background">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt="Slideshow"
            className={`background-image ${index === currentImageIndex ? 'active' : ''}`}
          />
        ))}
      </div>

      <div className='hero-grid'>
        <div className="side-text">
          <h2><i>for </i>Couples</h2>
          <h2><i>for </i>Events</h2>
          <h2><i>for </i>Individuals</h2>
        </div>

        <div className="logo">
          <img className="camera" src="/assets/logo-camera.png" alt="Camera" />
          <img className="text" src="/assets/logo-text.png" alt="Text" />
        </div>
      </div>

      <div className="navigation-buttons">
        <div className="arrow left" onClick={handlePrevious}><img src="/assets/arrow.svg" alt="Previous"/></div>
        <div className="arrow right" onClick={handleNext}><img src="/assets/arrow.svg" alt="Next"/></div>
      </div>
    </section>
  );
}

export default Hero;
