import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../css/contact.css';

const Contact = () => {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    date: null,  // Set to null for date picker
    sessionType: '',
    sessionVision: ''
  });

  const [errors, setErrors] = useState({});
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const checkFormComplete = () => {
      const isComplete = Object.values(formValues).every(value => value && value.toString().trim() !== '');
      setIsFormComplete(isComplete);
    };
    checkFormComplete();
  }, [formValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleDateChange = (date) => {
    setFormValues({
      ...formValues,
      date: date
    });
  };

  const validate = () => {
    const errors = {};
    if (!formValues.name) errors.name = '* Name is required';
    if (!formValues.email) errors.email = '* Email is required';
    if (!formValues.date) errors.date = '* Preferred date is required';
    if (!formValues.sessionType) errors.sessionType = '* Session type is required';
    if (!formValues.sessionVision) errors.sessionVision = '* Session vision is required';
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }

      const token = await executeRecaptcha('submit');

      if (!token) {
        alert('Please complete the CAPTCHA');
        return;
      }

      setErrors({});
      emailjs.send('service_ttue9te', 'template_t7pf77x', {
        ...formValues,
        date: formValues.date.toISOString().split('T')[0],  // Format the date
        'g-recaptcha-response': token
      }, 'YGlmMKgAzXHNpImHu')
        .then((result) => {
          console.log(result.text);
          setFormSubmitted(true);
        }, (error) => {
          console.log(error.text);
          alert('Failed to submit form. Please try again.');
        });
    }
  };

  return (
    <section id="contact" className="contact-section">
      <h2>Let's Connect</h2>
      <p>Want to work with me or have a question? Here is where you can reach out about weddings, couples, portraits, and all other sessions. I will get back to you as soon as I can!</p>
      <form className="contact-form" onSubmit={handleSubmit}>
        {errors.name && <span className="error-message">{errors.name}</span>}
        <input
          type="text"
          name="name"
          placeholder="Your Name *"
          value={formValues.name}
          onChange={handleChange}
          className="form-input"
        />

        {errors.email && <span className="error-message">{errors.email}</span>}
        <input
          type="email"
          name="email"
          placeholder="Your Email *"
          value={formValues.email}
          onChange={handleChange}
          className="form-input"
        />

        <input
          type="phone"
          name="number"
          placeholder="Phone Number (optional)"
          value={formValues.phone}
          onChange={handleChange}
          className="form-input"
        />

        {errors.date && <span className="error-message">{errors.date}</span>}
        <DatePicker
          selected={formValues.date}
          onChange={handleDateChange}
          placeholderText="Select a date"
          className="custom-date-picker"
          dateFormat="MMMM d, yyyy"
        />

        {errors.sessionType && <span className="error-message">{errors.sessionType}</span>}
        <select
          name="sessionType"
          value={formValues.sessionType}
          onChange={handleChange}
          className="form-input dropdown"
        >
          <option value="">Select session type *</option>
          <option value="Wedding">Wedding</option>
          <option value="Couples">Couples</option>
          <option value="Maternity">Maternity</option>
          <option value="Portraits">Portraits</option>
          <option value="Event">Event</option>
          <option value="Branding">Branding</option>
        </select>

        {errors.sessionVision && <span className="error-message">{errors.sessionVision}</span>}
        <textarea
          name="sessionVision"
          placeholder="What do you envision for your session? (Please share venue, times and any other details) *"
          value={formValues.sessionVision}
          onChange={handleChange}
          className="form-textarea"
        />

        <button 
          type="submit" 
          className={`form-button ${isFormComplete ? 'form-button-complete' : ''}`}>
          Submit
        </button>
      </form>
      {formSubmitted && (
        <div className="success-message">
          <img className='checkmark-icon' src="./assets/checkmark.svg" alt="Success" />
          Thank you for your message!
        </div>
      )}
    </section>
  );
};

export default Contact;
