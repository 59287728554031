import React from 'react';
import '../css/about.css';

const About = () => {
  return (
    <section id="about">
      <div className='about-grid'>
      <div className='left-grid'>
        <img className="profile-photo" src="./assets/profile.jpg"></img>
      </div>
        <div className='right-grid'>
        <p>Hey! I'm Amna, a Toronto based photographer soaking in the vibrant mix of this multicultural city over the past five years and making it the muse for my photo journey!
          I enjoy making my albums into a narrative that tells your compelling story. With a knack for attention to detail, I personally handle all editing to ensure you get a perfect blend of timeless, candid, and posed shots with whichever editing style you prefer!
          Beyond capturing moments, I lead a dual life as a creative coder at a digital agency. Dark chocolates, travel escapades, and competitive games of ping pong fuel my downtime activities. And I also draw for fun <a href='https://www.instagram.com/galwhodraws/' target='_blank'>@galwhodraws.</a>
        </p>
        <p>
          I'm eagerly prepared to take on projects, no matter their size so let's collaborate and bring your vision to life!</p>
        <a className="follow" href="https://www.instagram.com/galwhosnaps/?hl=en" target='_blank'>Follow Along</a>
      
      </div>
      <div className='small-image'>
        <img className="profile-photo" src="./assets/profile-photo.jpg"></img>
      </div>
      </div>
    </section>
  );
}

export default About;
