import React, { useEffect, useRef } from 'react';
import { Link } from 'react-scroll';
import Particles from 'react-tsparticles';
import '../css/services.css';

function Services() {
  const particlesRef = useRef(null);

  useEffect(() => {
    const canvas = document.querySelector('#tsparticles canvas');
    if (canvas) {
      canvas.style.position = 'absolute';
      canvas.style.top = '0';
      canvas.style.left = '0';
      canvas.style.width = '100%';
      canvas.style.height = '100%';
    }
  }, []);

  return (
    <section style={{position: 'relative', overflow: 'hidden', minHeight: '100vh' }}>
      <div className='sectional'><h1>These are your <br/>“once-in-a-lifetime” moments</h1></div>
      
      {/* Particles container */}
      <Particles
        id="tsparticles"
        ref={particlesRef}
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1 }}
        options={{
          background: {
            color: {
              value: "transparent", // Set particles background color as transparent
            },
          },
          fpsLimit: 60,
          particles: {
            color: {
              value: ["#0fbbb4", "#fdc14c"],
            },
            move: {
              direction: "none",
              enable: true,
              outMode: "out",
              random: false,
              speed: 2,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 8,
            },
            opacity: {
              value: 1,
            },
            shape: {
              type: "circle",
            },
            size: {
              random: true,
              value: 4,
            },
          },
          detectRetina: true,
        }}
      />
      
      {/* Content */}
      <div id="services" className='aboutServices' style={{ position: 'relative', zIndex: 1, paddingTop: '50px' }}>
        <div className='left-col'>
          <h6> — Services</h6>
          <h1>Dreamy, Timeless & Authentic</h1>
          <p>
            I understand that planning your wedding budget can feel overwhelming – I've been there! But, speaking from experience, your wedding photos are genuinely one of the most crucial investments for your big day. While the dress, cake, and flowers may fade away, your wedding gallery will endure as a timeless keepsake for generations. With me know that your photos are in caring hands is the best assurance you can have on the most important day of your life.
          </p>
          <Link to="contact" className='custom-link' smooth={true} duration={1000}>Inquire Now for your Customised Quote</Link>
        </div>
        <div className='right-col'>
          <div className='service'>
            <h5>Weddings ($1.5-4K)</h5>
            <div className='horizontalSeperator'></div>
            <p>Each package may include:</p>
            <div className='text-col'>
              <div>
                <p>
                  4, 6, 8, or 10 hours of coverage<br />
                  500-1,000+ digital images<br />
                  30-60 min. engagement shoot
                </p>
              </div>
              <div>
                <p>
                  Second Professional Photographer<br />
                  Personalized timeline assistance<br />
                  Full gallery within 3 weeks
                </p>
              </div>
            </div>
          </div>

          <div className='service'>
            <h5>Couples ($300+)</h5>
            <div className='horizontalSeperator'></div>
            <p>
              For engagements, proposals, elopements, maternity, or just because. Each session includes 60 minutes of shooting, 75+ digital photos, a what to wear guide if interested, printing rights, and full gallery within 1 week.
            </p>
          </div>

          <div className='service'>
            <h5>Others ($300+)</h5>
            <div className='horizontalSeperator'></div>
            <p>
              For portraits, family, branding, or lifestyle, each session includes 60+ minutes of shooting, 75+ digital photos, printing rights, and full gallery within 1 week.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
