export const hideAllItems = () => {
    const items = document.querySelectorAll('.item');
    items.forEach(item => {
      setTimeout(() => {
        item.style.opacity = 0;
      }, 200);
      item.style.display = 'none';
    });
  };
  
  export const showItemsByCategory = (category) => {
    const items = document.querySelectorAll(`.item.${category}`);
    items.forEach(item => {
      item.style.opacity = 0;
      item.style.display = 'block';
  
      // Triggering reflow to enable transition
      void item.offsetWidth;
      setTimeout(() => {
        item.style.opacity = 1;
      }, 200);
    });
  };
  
  export const updateTitleText = (prefix, suffix, setTitleText) => {
    setTitleText({ prefix, suffix });
  };
  