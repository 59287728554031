import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import "../css/portfolio.css";
import { hideAllItems, showItemsByCategory, updateTitleText } from './helpers';

const Portfolio = () => {
  const [activeCategory, setActiveCategory] = useState('featured');
  const [titleText, setTitleText] = useState({ prefix: '01', suffix: 'LOVE' });

  useEffect(() => {
    hideAllItems();
    showItemsByCategory(activeCategory);
    switch (activeCategory) {
      case 'featured':
        updateTitleText('01', 'LOVE', setTitleText);
        break;
      case 'weddings':
        updateTitleText('02', 'VOWS', setTitleText);
        break;
      case 'couples':
        updateTitleText('03', 'MOMENTS', setTitleText);
        break;
      case 'maternity':
        updateTitleText('04', 'JOY', setTitleText);
        break;
      case 'branding':
        updateTitleText('05', 'ESSENCE', setTitleText);
        break;
      case 'travel':
        updateTitleText('06', 'DISCOVERY', setTitleText);
        break;
      default:
        updateTitleText('01', 'LOVE', setTitleText);
        break;
    }
  }, [activeCategory]);

  const categories = [
    { name: 'featured', label: 'Featured' },
    { name: 'weddings', label: 'Weddings' },
    { name: 'couples', label: 'Couples' },
    { name: 'maternity', label: 'Maternity' },
    { name: 'branding', label: 'Branding' },
    { name: 'travel', label: 'Travel' }
  ];

  const images = {
    featured: [
      {
        src: "/assets/portfolio/featured/3.jpg",
        title: "Timeless Elegance",
        description: "An intimate wedding ceremony captured at a elegant venue."
      },
      {
        src: "./assets/portfolio/featured/1.jpg",
        title: "White Roses",
        description: "The newlyweds sharing cherished moments right after their Nikkah ceremony."
      },

      {
        src: "./assets/portfolio/featured/2.jpg",
        title: "Sand & Sea",
        description: "A joyful photoshoot announcing the arrival of their first baby."
      },

      {
        src: "./assets/portfolio/featured/4.jpg",
        title: "Castles & Dreams",
        description: "A romantic prewedding shoot in a dreamy setting."
      },
      {
        src: "./assets/portfolio/featured/5.jpg",
        title: "Lil New Yorker",
        description: "A maternity shoot blending urban energy."
      },
      {
        src: "./assets/portfolio/featured/6.jpg",
        title: "A Red Knot",
        description: "A romantic sunlit kiss marking the beginning of a new chapter."
      }
    ],
    weddings: [
      {
        src: "/assets/portfolio/featured/timelesselegance.jpg",
        title: "Timeless Elegance",
        description: "An intimate wedding ceremony captured at a elegant venue."
      },
      {
        src: "/assets/portfolio/featured/union.jpg",
        title: "Union",
        description: "A prewedding shoot set in the heart of downtown Toronto."
      },
      {
        src: "/assets/portfolio/featured/seoulmates.jpg",
        title: "Seoulmates",
        description: "An intimate exchange of vows in the Toronto City Hall."
      },
      {
        src: "/assets/portfolio/featured/princessgate.jpg",
        title: "Princess Gate",
        description: "A lovely couples shoot on a chilly, fog-filled spring evening in downtown Toronto."
      },
      {
        src: "/assets/portfolio/featured/whiteroses.jpg",
        title: "White Roses",
        description: "The bride and groom's nikkah ceremony and couple's shoot."
      },
      {
        src: "/assets/portfolio/featured/firstdate.jpg",
        title: "First Date",
        description: "Celebrating the beautiful wedding that followed after their perfect date."
      }
    ],
    couples: [
      {
        src: "/assets/portfolio/featured/aredknot.jpg",
        title: "A Red Knot",
        description: "A romantic sunlit kiss marking the beginning of a new chapter."
     },
      {
        src: "/assets/portfolio/featured/castlesanddreams.jpg",
        title: "Castles & Dreams",
        description: "A romantic prewedding shoot in a dreamy setting." 
      },
      {
        src: "/assets/portfolio/featured/whisperedvows.jpg",
        title: "Whispered Vows",
        description: "An intimate proposal unfolding in a secluded spot in Trillium park."
      },
      {
        src: "/assets/portfolio/featured/oldfilm.jpg",
        title: "Old Film",
        description: "A film-like elopement shoot in Toronto."
      },
      {
        src: "/assets/portfolio/featured/summerglow.jpg",
        title: "Summer Glow",
        description: "Shooting the cover for a heartfelt love song, set against the serene backdrop of botanical gardens"
      },
      {
        src: "/assets/portfolio/featured/blushofolympus.jpg",
        title: "Blush of Olympus",
        description: "A couples shoot in the gardens of Guild Inn Estate"
      }
    ],
    maternity: [
      {
        src: "/assets/portfolio/featured/sandandsea.jpg",
        title: "Sand & Sea",
        description: "A joyful photoshoot announcing the arrival of their first baby."
      },
      {
        src: "/assets/portfolio/featured/lilnewyorker.jpg",
        title: "Lil New Yorker",
        description: "A maternity shoot blending urban energy"
      },
      {
        src: "/assets/portfolio/featured/newbeginnings.jpg",
        title: "New Beginnings",
        description: "A maternity shoot at Old Mills, celebrating the anticipation and joy of welcoming their firstborn."
      }
    ],
    branding: [
      {
        src: "/assets/portfolio/featured/bestofjune.jpg",
        title: "Best of June",
        description: "A portrait session capturing the author’s creativity and essence."
      },
      {
        src: "/assets/portfolio/featured/healiness.jpg",
        title: "Healiness",
        description: "A branding portrait shoot showcasing the heart and personality of the business owners."
      },
      {
        src: "/assets/portfolio/featured/harmonies.jpg",
        title: "Harmonies",
        description: "Shooting the Spotify cover for a heartfelt love song, set against the serene backdrop of a greenhouse"
      }
    ],
    travel: [
      {
        src: "/assets/portfolio/featured/tulum.jpg",
        title: "Tulum",
        description: "Street photography on a tourist busy Tulum."
      },
      {
        src: "/assets/portfolio/featured/cancun.jpg",
        title: "Cancun",
        description: "A vibrant travel shoot in Cancun, capturing the beauty of its beaches and tropical vibes."
      },
      {
        src: "/assets/portfolio/featured/banff.jpg",
        title: "Banff",
        description: "A spring visit to Banff and some nature photography."
      }
    ]
  };

  return (
    <section id="portfolio">
      <div id="title-text">
        <h1>
          {/* , fontFamily: 'var(--font-cormorant) */}
          <span className='bigNumber'>{titleText.prefix}</span>
          <em>for</em> {titleText.suffix}
        </h1>
        <div id="filter">
          {categories.map(category => (
            <button
              key={category.name}
              className={`filter-btn ${activeCategory === category.name ? 'active' : ''}`}
              onClick={() => setActiveCategory(category.name)}
            >
              {category.label}
            </button>
          ))}

        {/* <a className='custom-link' href='https://pitch.com/v/photosbyamna-x86taq'>View All</a> */}
        </div>
      </div>
      <div id="gallery" className="gallery">
        {Object.keys(images).map(category =>
          images[category].map(({ src, title, description }, index) => (
          <Link to={`/${title.replace(/\s+/g, "").replace(/&/g, "and")}`} key={index} className={`item ${category}`}>
            <img src={src} alt={`Image ${index + 1}`} />
            <h3>{title}</h3>
            <p>{description}</p>
          </Link>

          ))
        )}
      </div>
    </section>
  );
};

export default Portfolio;
