import React, { useState } from 'react';
import ScrollToSectionLink from './ScrollToSectionLink';
import '../css/header.css';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <div className={`content ${menuOpen ? 'shifted' : ''}`}>
        <header className="header">
          <div className='left-header'>
            <ul className="nav">
              <li>
                <a href="https://www.instagram.com/galwhosnaps/?hl=en" target='_blank' rel="noreferrer">
                  <img className='instagram' src="/assets/instagram-black.svg" alt="Instagram" />
                </a>
              </li>
              <li>
                <ScrollToSectionLink to="about">About</ScrollToSectionLink>
              </li>
              <li>
                <ScrollToSectionLink to="portfolio">Portfolio</ScrollToSectionLink>
              </li>
            </ul>
          </div>
          <div>
            <ScrollToSectionLink to="hero">
              <img className='header-logo' style={{ width: '60px', height: 'auto' }} src="/assets/secondary-logo.png" alt="Secondary Logo" />
            </ScrollToSectionLink>
          </div>
          <div className='right-header'>
            <ul className="nav">
              <li>
                <ScrollToSectionLink to="services">Services</ScrollToSectionLink>
              </li>
              <li>
                <a href='https://calendly.com/photosbyamna/60min' target='_blank' rel="noreferrer">Book Minis</a>
              </li>
              <li>
                <ScrollToSectionLink to="contact">Contact</ScrollToSectionLink>
              </li>
            </ul>
          </div>

          <div className={`hamburger ${menuOpen ? 'is-active' : ''}`} onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </header>
      </div>

      <div className={`mobile-menu ${menuOpen ? 'is-active' : ''}`}>
        <button className='close-btn' onClick={toggleMenu}></button>
        <ul className="nav">
          <li onClick={toggleMenu}>
            <ScrollToSectionLink to="about">About</ScrollToSectionLink>
          </li>
          <li onClick={toggleMenu}>
            <ScrollToSectionLink to="portfolio">Portfolio</ScrollToSectionLink>
          </li>
          <li onClick={toggleMenu}>
            <ScrollToSectionLink to="services">Services</ScrollToSectionLink>
          </li>
          <li onClick={toggleMenu}>
            <ScrollToSectionLink to="contact">Contact</ScrollToSectionLink>
          </li>
          <li onClick={toggleMenu}>
            <a href='https://calendly.com/photosbyamna/60min' target='_blank' rel="noreferrer" onClick={toggleMenu}>
              Book Minis
            </a>
          </li>
          <li onClick={toggleMenu}>
            <a href="https://www.instagram.com/galwhosnaps/?hl=en" target='_blank' rel="noreferrer" onClick={toggleMenu}>
              <img className='instagram' src="/assets/instagram-white.svg" alt="Instagram" />
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Header;
